<template>
  <div class="app-container">
    <div class="showarea" ref="showareaD">
      <div class="chat-title">欢迎,请在下方描述图片</div>
      <div v-for="(message, index) in messages" :class="[message.role == 'user' ? 'user-question' : 'ai-answer']"
        :key="index">
        <div class="message">
          <div class="msg-icon">
            <img v-if="message.role == 'user'" src="../../../assets/img/icon1.png" alt="">
            <img v-else src="../../../assets/img/machine.png" alt="">
          </div>
          <div class="msg-content">
            <img src="/static/loading-point.gif" v-if="!message.content && !message.errorMsg" />
            <div v-else>
              <div v-if="message.role == 'user'">{{ message.content }}</div>
              <div v-else class="img-box">
                <span v-if="message.errorMsg">{{ message.errorMsg }}</span>
                <span v-else style="display: block;overflow: hidden;">
                  <el-image v-for="(list, i) in message.content" :key="i" :src="list"
                    :preview-src-list="message.content || []">
                  </el-image>
                </span>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <div class="border-box"></div>
      <el-form class="el-form-box" ref="formMessage" :model="formMessage" v-loading="formMessageLoading"
        @submit.native.prevent>
        <el-form-item label="" prop="key" style="flex:1">
          <el-input v-model="formMessage.key" placeholder="请描述您想生成的图片">
            <el-button slot="append" :icon="submitIcon" type="primary" v-preventReClick @click="send"></el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="tips">
       所有生成的图片由人工智能模型自动生成，其准确性和完整性不能保证，也不代表我们的态度和观点。
      </div>
    </div>

  </div>
</template>
<script>
import { generationImages } from '@/api/ai/image'
import 'highlight.js/styles/monokai-sublime.css'
export default {
  name: 'AiChat',
  data() {
    return {
      submitIcon: 'el-icon-position',
      formMessageLoading: false,
      formMessage: {
        key: undefined,
        prompt: '',
        history: []
      },
      messages: [],
      rulesformMessage: {
        key: [{ required: true, message: '' }]
      }
    }
  },
  mounted() { 
    const windowHeight = window.innerHeight
    this.$refs.showareaD.style.height = windowHeight - 100 + 'px'
    console.log(this.$refs.showareaD.style.height)
  },
  methods: {
    messageScrollToBottom(){
      this.$nextTick(()=>{
        let scrollHeight=this.$refs.showareaD.scrollHeight;
        console.log('scrollHeight:'+scrollHeight);
        this.$refs.showareaD.scrollTo({
        top: scrollHeight+200,
        behavior: 'smooth',
       });
      })
    },
    // 发送消息
    send() {
      if (this.submitIcon === 'el-icon-loading') return
      if (!this.formMessage.key) return
      let msg = {}
      msg.role = 'user'
      msg.content = this.formMessage.key
      this.messages.push(msg)
      this.submitIcon = 'el-icon-loading'
      msg = {}
      msg.role = 'assistant'
      msg.content = ''
      this.messages.push(msg)
      generationImages({
        prompt: this.formMessage.key,
        n: 4,
        size: '512x512',
        response_format: 'b64_json'
      }).then((res) => {
        this.errorMsg = ''
        this.formMessage.key = ''
        this.submitIcon = 'el-icon-position'
        if (res.data.success) {
          this.messages[this.messages.length - 1].content = res.data.result
          const arry = this.messages[this.messages.length - 1].content.map(e => {
            return 'data:image/png;base64,' + e
          })
          this.messages[this.messages.length - 1].content = arry
          this.messages[this.messages.length - 1].errorMsg = ''
        } else {
          this.messages[this.messages.length - 1].content = ''
          this.messages[this.messages.length - 1].errorMsg = res.data.message
        }
         this.messageScrollToBottom();
      })
    }
  }
}
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
.tips {
  text-align: center;
  margin: 0 0 15px 0;
  /* transform: scale(.5);
    transform-origin: center top; */
  /* color: #878aab; */
  color: #b4b9c3;
  font-size: 12px;
}
.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
  background: #f5f5f5;
}

.bottom_box {
  position: fixed;
  bottom: 0;
  /* left: 200px; */
  width: calc(100% - 240px);
  /* padding: 0 20px; */
  background-color: #fff;
}

.el-form-box {
overflow: hidden;
  padding-top: 30px;
  background: #fff;
  /* height: 100px; */
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.border-box {
  border-top: 1px solid #eeeeee;
}

.showarea {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  position: relative;
  padding-bottom: 150px;
}

.message {
  margin: 0 auto;
  display: flex;
  color: #fff;
}

.user-question .message {
  flex-direction: row-reverse;
}

.msg-icon {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  background: #2d6ce9;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.msg-icon img {
  width: 30px;
  height: 30px;
  border-radius: 100px;
}

.msg-content {
  margin: 0 5px;
  border-radius: 10px;
  padding: 5px;
  min-width: 100px;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  word-break: break-all;
  color: #232323;
}

.user-question .msg-content {
  background: #fff;
}

.el-avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.ai-answer {
  padding: 8px 0 0 0;
}

.user-question {
  padding: 8px 0 0 0;
}

.wrap {
  caret-color: black;
}

.el-button--primary {
  --tw-bg-opacity: 1;
  background-color: #eeeeee;
}

.el-button {
  border: 0px;
  line-height: 16px;
}

@media only screen and (max-width: 768px) {
  [class*="bottom_box"] {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    width: 100%;
    background: #fff;
  }

  [class*="showarea"] {
    width: 100%;
  }

  [class*="el-form-box"] {
    width: 100%;
  }

  [class*="showarea"] {
    width: 100%;
  }
}

.el-main {
  background: #f5f5f5 !important;
}

pre {
  background: #000;
  display: block;
  overflow: auto;
  color: #fff;
}

.img-box {
  overflow: hidden;
}

.img-box img {
  width: 50%;
  float: left;
}

.el-image {
  position: relative;
  display: block;
  overflow: hidden;
  width: calc(50% - 2px);
  float: left;
  margin-left: 2px;
  margin-bottom: 2px;
}
</style>
